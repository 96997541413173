import Menu from "src/components/menu/Menu";
import Footer from "src/footer/Footer";
import ISettings from "src/lib/types/Settings";
import Script from 'next/script';

//#region [Props]
type DefaultLayoutProps = {
	children: React.ReactNode;
	appSettings?: ISettings;
};
//#endregion

//#region [Component]
export default function DefaultLayout({ children, appSettings }: DefaultLayoutProps) {
	if (!appSettings) {
		console.error("Appsettings is null");
		return null;
	}

	return <main>

		<Menu settings={appSettings} />
		{children}
		<Footer appSettings={appSettings}/>
	</main>

}
//#endregion

//#region [Styles]

//#endregion