import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import FilmwebLogoSvg from 'src/components/svg/FilmwebLogoSvg';
import SomeFacebookSquareLogoSvg from 'src/components/svg/SomeFacebookSquareLogoSvg';
import SomeInstagramSquareLogoSvg from 'src/components/svg/SomeInstagramSquareLogoSvg';
import SomeTikTokLogoSvg from 'src/components/svg/SomeTikTokLogoSvg';
import TMDBSvg from 'src/components/svg/TMDBSvg';
import theme from 'src/components/themes/DefaultTheme';
import FooterHeading from 'src/footer/FooterHeading';
import Newsletter from 'src/footer/Newsletter';
import styled from "src/lib/styles/css";
import ISettings from "src/lib/types/Settings";
//#region [Styles]
const SFooter = styled.footer`
	background-color: ${theme.mainColor};
	color: var(--buttontextcolor);
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 20px 70px;
`;

const SFooterContainer = styled.div`
	@media ${theme.mq.desktop} {
		max-width: 350px;
		margin: 0 auto;
	}

	a {
		color: var(--buttontextcolor);
	}
`;

const SFooterSection = styled.div`
	padding-top: 50px;
	text-align: center;

	svg {
		width: 100%;
	}
`;

const SLinks = styled.div`
	padding-top: 20px;
	padding-bottom: 15px;

	a {
		display: block;
	}
`;

const SSomeLinks = styled.div`
	padding-top: 10px;
	width: 50%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;

	svg {
		max-height: 26px;
	}

`;

const SCookieSection = styled(SFooterSection)`
	font-size: 14px;
`;

const SLogoSection = styled.div`
	padding-top: 20px;
	max-width: 125px;
	margin: 0 auto;
`;
//#endregion

//#region [Props]
type FooterProps = {
    appSettings: ISettings;
};
//#endregion

//#region [Component]
export default function Footer({ appSettings}: FooterProps) {

	return <SFooter>
		<SFooterContainer>
			<Newsletter />
			<SFooterSection>
				<FooterHeading>Kundeservice</FooterHeading>
				<div>Vi hjelper deg gjerne.</div>
				<div>Her finner du svar på dine spørsmål:</div>
				<SLinks>
					<FilmwebLink to="https://kundeservice.filmweb.no/">Kundeservice</FilmwebLink>
					<FilmwebLink to="https://annonse.filmweb.no/nb/">Annonsere på Filmweb?</FilmwebLink>
				</SLinks>
			</SFooterSection>
			<SFooterSection>
				<FooterHeading>Våre tjenester</FooterHeading>
				<div>Vi tilbyr en rekke</div>
				<FilmwebLink to="/filmnytt/filmweb-sine-tjenester">tjenester og produkter</FilmwebLink>.
			</SFooterSection>
			<SFooterSection>
				<FooterHeading>Følg oss</FooterHeading>
				<SSomeLinks>
					<FilmwebLink to="https://www.facebook.com/filmweb.no" rel="noopener noreferrer"><SomeFacebookSquareLogoSvg /></FilmwebLink>
					<FilmwebLink to="https://www.instagram.com/filmwebno/" rel="noopener noreferrer"><SomeInstagramSquareLogoSvg /></FilmwebLink>
					<FilmwebLink to="https://www.tiktok.com/@filmweb.no" rel="noopener noreferrer"><SomeTikTokLogoSvg /></FilmwebLink>
				</SSomeLinks>
			</SFooterSection>
			<SFooterSection>
				This product uses the TMDB API but is not endorsed or certified by TMDB.
				<FilmwebLink to="https://www.themoviedb.org/" rel="noopener noreferrer"><TMDBSvg /></FilmwebLink>
			</SFooterSection>
			<SCookieSection>
				{/** TODO: replace urls */}
				<div>Filmweb bruker <FilmwebLink to={`/filmnytt/${appSettings.cookiePolicy.current}`}>cookies</FilmwebLink>. Våre <FilmwebLink to={`/filmnytt/${appSettings.termsAndConditions.current}`}>brukervilkår</FilmwebLink>.</div>
				<div>Adm. Dir: Harald Løbak Sæther</div>
			</SCookieSection>
			<SLogoSection>
				<FilmwebLogoSvg/>
			</SLogoSection>
		</SFooterContainer>
	</SFooter>;
}
//#endregion