import styled from 'src/lib/styles/css';

//#region [Props]
type ExternalImageForUrlProps = {
	imageUrl: string;
	alt: string;
	width?: string;
	height?: string;
	rel?: string;
	className?: string;
};
//#endregion

//#region [Component]
export default function ExternalImageForUrl({ imageUrl, alt, rel, className }: ExternalImageForUrlProps) {
	// eslint-disable-next-line @next/next/no-img-element
	return <img src={imageUrl} alt={alt} rel={rel} className={className}/>;
}
//#endregion

//#region [Styles]

//#endregion