import { Dispatch } from 'react';
import styled from "src/lib/styles/css";
import theme from 'src/components/themes/DefaultTheme';
import { IMenuAction } from 'src/components/menu/SearchAndDropdownMenu';


//#region [Props]
type MainMenuButtonProps = {
	dispatch: Dispatch<IMenuAction>;
};
//#endregion

//#region [Component]
export default function MainMenuButton({ dispatch }: MainMenuButtonProps) {
	return <SMainMenuButton onClick={() => dispatch({ type: "TOGGLE_MENU" })} aria-label="Meny">
		<SHamburger>
			<span></span>
		</SHamburger>
		<div>Meny</div>
	</SMainMenuButton>;
}
//#endregion

//#region [Styles]
const SMainMenuButton = styled.button`
	font-size: 10px;
	color: ${theme.textColor};
	line-height: 12px;

	background-color: #0000;
    border: 0;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    overflow: visible;
    padding: 0 10px;
    text-transform: none;
    transition-duration: .15s;
    transition-property: opacity,filter;
    transition-timing-function: linear;
`;

const SHamburger = styled.div`
	display: inline-block;
    height: 21px;
    position: relative;
    width: 30px;

	span {
		background-color: ${theme.textColor};
		border-radius: 4px;
		height: 3px;
		position: absolute;
		transition-property: transform;
		width: 30px;

		transition-duration: .22s;
    	transition-timing-function: cubic-bezier(.55,.055,.675,.19);

		display: block;
    	margin-top: -1.5px;
    	top: 50%;

		&::before, &:after {
			content: "";
    		display: block;

			background-color: ${theme.textColor};
			border-radius: 4px;
			height: 3px;
			position: absolute;
			transition-duration: .15s;
			transition-property: transform;
			transition-timing-function: ease;
			width: 30px;
		}

		&:before {

			top: -9px;
			transition: top .1s ease-in .25s,opacity .1s ease-in;
		}

		&:after {
			bottom: -9px;
			transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19);
		}
	}
`;



//#endregion