import FullPageOverlay from "src/components/fullpageoverlay/FullPageOverlay";
import CloseSvg from "src/components/svg/CloseSvg";
import theme from "src/components/themes/DefaultTheme";
import styled from 'src/lib/styles/css';
import { useEffect } from "react";

//#region [Props]
type SelectorWrapperProps = {
	children: React.ReactNode;
	onClose: () => void;
	heading?: string;
};
//#endregion

//#region [Component]
export default function SelectorWrapper({ children, onClose, heading }: SelectorWrapperProps) {
	useEffect(() => {
		const escHandler = (evt: KeyboardEvent) => {
			if (evt.code === "Escape") {
				onClose();
			}
		};

		document.addEventListener("keydown", escHandler, false);

		return () => {
			document.removeEventListener("keydown", escHandler, false);
		};
	}, [onClose]);

	return <FullPageOverlay hideCloseButton>
		<SDialogContent onClick={(e) => e.stopPropagation()}>
			<SCloseButton onClick={onClose}>
				<CloseSvg />
			</SCloseButton>
			{heading && <SHeading>{heading}</SHeading>}
			{children}
		</SDialogContent>
	</FullPageOverlay>;
}
//#endregion

//#region [Styles]
const SDialogContent = styled.div`
	z-index: 20;
	color: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	container-type: inline-size;
	padding: 10px 0;
	background-color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--backgroundalphaheavy));

	@media ${theme.mq.desktop} {
		position: absolute;
		//@media (orientation: landscape) {
		border-radius: 10px;
		top: 50%;
		width: 600px;
		left: 50%;
		transform: translate(-50%, -50%);
		bottom: unset;
		right: unset;
		height: 600px;
		background-color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--backgroundcolor));
	}
`;

const SCloseButton = styled.button`
	position: absolute;
	top: 14px;
	right: 25px;
	width: 50px;
	display: block;
	border: none;
	background-color: transparent;
	color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--buttontextcolor));
	cursor: pointer;

	svg {
		width: 100%;
	}
`;

const SHeading = styled.div`
	width: 100%;
	text-align: center;
	font-size: 24px;
	font-weight: normal;

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		padding-top: 75px;
	}
`;
//#endregion