import theme from "src/components/themes/DefaultTheme";
import styled, { css } from "src/lib/styles/css";

//#region [Styles]
type SButtonProps = {
    $borderStyle?: "round" | "square";
    $inverse?: boolean;
    $isLarge?: boolean;
    $isOutline?: boolean;
}

export function getButtonCss(borderStyle?: "round" | "square") {
    return css`
        white-space: nowrap;
        border: none;
        background-color: var(--buttoncolor, ${theme.buttonColor});
        color: var(--buttontextcolor, ${theme.textColor});
        line-height: 1;
        font-size: 14px;
        font-weight: 550;
        text-align: center;
        min-height: 34px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.5s, color 0.5s, opacity 0.5s;

        &[disabled] {
            opacity: 0.5;
        }

        &:hover {
            cursor: pointer;
        }

        @media ${theme.mq.desktop} {
            //@media  (orientation: landscape) {
            padding: 0.5em 24px;
        }

        ${borderStyle === "round" && css`
		    border-radius: 2em; // this is font size + 2 * vertical padding
		    padding: 0.5em 1em;
	    `}

        ${borderStyle === "square" && css`
            border-radius: 4px;
            padding: 0.5em;
        `}
    `;
}

export const SButton = styled.button<SButtonProps>`
	${props => getButtonCss(props.$borderStyle)}

	svg {
		height: 18px;
		max-height: 18px;
		margin-right: 8px;
		fill: var(--iconcolor, ${theme.textColor});
		vertical-align: top;
	}
	/* This does not seem to work
	& + & {
		@media ${theme.mq.desktop} {
			//@media  (orientation: landscape) {
			margin-left: 30px;
		}
	}*/


	${props => props.$inverse && css`
		background-color: var(--buttontextcolor, ${theme.textColor});
		color: var(--buttoncolor, ${theme.buttonColor});

		svg, path {
			fill: var(--buttoncolor, ${theme.buttonColor});
		}
	`}

	${props => props.$isLarge && css`
		min-height: 44px;
		font-size: 16px;
	`}

	${props => props.$isOutline && css`
		background: transparent;
		border: 2px solid var(--buttoncolor, ${theme.buttonColor});
	`}
`;
//#endregion

//#region [Props]
type ButtonProps = {
    text: string;
    inverse?: boolean;
    disabled?: boolean;
    icon?: React.ReactNode;
    className?: string;
    isOutline?: boolean;
    title?: string;
    isLarge?: boolean;
    borderStyle?: "round" | "square"
} & React.HTMLAttributes<HTMLButtonElement>;
//#endregion

//#region [Component]
export default function Button({
    onClick,
    text,
    inverse = false,
    disabled = false,
    icon,
    className,
    isOutline = false,
    title,
    isLarge = false,
    borderStyle = "round",
    ...rest
}: ButtonProps) {
    return <SButton onClick={onClick} disabled={disabled} className={className} title={title} $borderStyle={borderStyle} $isLarge={isLarge} $inverse={inverse} $isOutline={isOutline} {...rest}>
        {icon}
        {text}
    </SButton>;
}
//#endregion