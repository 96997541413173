
import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg } from 'src/components/svg/SvgStyles';
import theme from 'src/components/themes/DefaultTheme';
import styled from "src/lib/styles/css";
//#region [Styles]
// not using css template here because we need the palette
const SFilmwebLogoSvg = styled(SDefaultSvg)`
	path {
		fill: ${theme.palette.gullHovedfarge};
	}
`;

//#endregion

//#region [Component]
export default function FilmwebLogoSvg({ className }: StandardSvgProps) {

	return (
		<SFilmwebLogoSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.573 25">
			<path d="M806.345,212.727a6.912,6.912,0,0,1,1.223,3.992,7.076,7.076,0,0,1-6.879,7.267h-8.507v-24.6h8.64a5.772,5.772,0,0,1,5.659,5.868c0,2.013-.848,3.446-2.847,5.015A9.489,9.489,0,0,1,806.345,212.727Zm-6.64-9.143h-2.846V208.7h2.507a2.507,2.507,0,0,0,2.406-2.661A2.275,2.275,0,0,0,799.7,203.583Zm0,9.446h-2.846v6.792h2.473a3.567,3.567,0,0,0,3.8-3.479,3.375,3.375,0,0,0-3.422-3.309Zm-18.942,10.8a7.5,7.5,0,0,1-3.045-.988,4.9,4.9,0,0,1-2.133-2.522,12.989,12.989,0,0,1-.542-3.1v-11.45a6.426,6.426,0,0,1,6.263-6.408h6.9v4.193h-5.89a2.319,2.319,0,0,0-2.606,2.522v3.615h8.5v4.159h-8.493v3.068c0,1.874.676,2.863,2.03,2.863h6.463v4.159h-5.687a17.215,17.215,0,0,1-1.756-.1Zm-17.04.066a6.168,6.168,0,0,1-5.1-2.367,6.224,6.224,0,0,1-5.1,2.434,6.773,6.773,0,0,1-6.887-6.8v-10.5h-.013v-.886a2.727,2.727,0,0,0-2.445-2.238,3.08,3.08,0,0,0-3.013,3.134v17.039h-4.536V206.27a2.617,2.617,0,0,0-2.649-2.635,2.83,2.83,0,0,0-2.814,2.868v17.2h-4.57V206.437a7.228,7.228,0,0,1,7.516-7.068,6.168,6.168,0,0,1,5.1,2.367,6.222,6.222,0,0,1,5.1-2.434,6.773,6.773,0,0,1,6.892,6.8v7.278h.022v4.167a2.724,2.724,0,0,0,2.436,2.186,3.08,3.08,0,0,0,3.013-3.135V199.561h4.536V217a2.616,2.616,0,0,0,2.649,2.634,2.829,2.829,0,0,0,2.815-2.867v-17.2h4.57v17.272a7.234,7.234,0,0,1-7.521,7.068Zm-48.457-.071a7.5,7.5,0,0,1-3.045-.988,4.908,4.908,0,0,1-2.133-2.522,13.013,13.013,0,0,1-.542-3.1v-.122h0v-17.7h4.667v17.7h.018c.044,1.753.713,2.685,2.022,2.685h6.466v4.159h-5.687a17.228,17.228,0,0,1-1.766-.1Zm-11.831-19.787a2.527,2.527,0,1,1,2.509-2.526,2.527,2.527,0,0,1-2.509,2.526Zm-13.8,19.734H685V206.612a9.368,9.368,0,0,1,.2-2.129,5.374,5.374,0,0,1,1.41-2.737,7.237,7.237,0,0,1,5.2-2.332h5.537v4.157h-5.268c-1.544,0-2.45.811-2.45,2.162v3.919h7.718v4.156h-7.718v9.968Zm16.126.18h-4.677V206.668h4.674v17.289Z" transform="translate(-684.998 -198.985)" />
		</SFilmwebLogoSvg>
	);
}
//#endregion
