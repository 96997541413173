import LoadingIndicator from 'src/components/loadingindicator/LoadingIndicator';
import theme from 'src/components/themes/DefaultTheme';
import styled, { css } from "src/lib/styles/css";
//#region [Styles]
type SSuspenseFallbackProps = {
	$isFullPage: boolean;
}

const SSuspenseFallback = styled.div<SSuspenseFallbackProps>`
	margin: 115px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	${props => props.$isFullPage && css`
		min-height: 100vh;
		min-height: 100dvh;
	`}
`;

const SWaitText = styled.p`
	color: var(--textcolor, ${theme.textColor});
`;
//#endregion

//#region [Props]
type SuspenseFallbackProps = {
	className?: string
	text?: string;
	fullPage?: boolean;
};
//#endregion

//#region [Component]
export default function SuspenseFallback({ className, text, fullPage = false }: SuspenseFallbackProps) {
	return <SSuspenseFallback $isFullPage={fullPage} className={className}>
		<LoadingIndicator position='InlineCenter' />
		{text && <SWaitText>{text}</SWaitText>}
	</SSuspenseFallback>;
}
//#endregion