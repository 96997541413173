import LocationResult from 'src/components/locationresultlist/LocationResult';
import theme from 'src/components/themes/DefaultTheme';
import styled from "src/lib/styles/css";
//#region [Styles]
const SLocationResultList = styled.section`
	--max-results: 6;

	max-width: ${theme.maxContentWidth}px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
`;

const SH1 = styled.h1`
	font-size: 20px;
	flex: 0 0 100%;
	margin: 1rem 0;
	color: ${theme.textColor};
	text-align: center;
`;

const SLocationItem = styled(LocationResult)`
	width: 100%;
	margin-bottom: 12px;

	@media ${theme.mq.desktop} {
		width: calc(100% / var(--max-results) - 30px);

		&:not(:nth-child(6n+1)) {
			margin-right: calc(30px * var(--max-results) / (var(--max-results) - 1));
		}
	}
`;

//#endregion

//#region [Props]
type LocationResultListProps = {
	locationList: string[];
	maxNumToShow?: number;
	className?: string;
	clickTracker: (locationName: string) => void;
};
//#endregion

//#region [Component]
export default function LocationResultList({ locationList, maxNumToShow = 6, className, clickTracker }: LocationResultListProps) {
	return <SLocationResultList className={className}>
		<SH1>Treff på kinosteder</SH1>
		{locationList!.slice(0, maxNumToShow).map(locationName => (<SLocationItem locationName={locationName} key={locationName} onClick={() => clickTracker(locationName)} />))}
	</SLocationResultList>;
}
//#endregion