import { BaseCmsImageProps } from "src/components/images/BaseCmsImageProps";
import { doCrop, getImageLoader, getOrgSize } from "src/components/images/imageFunctions";
import { urlForAsset } from "src/lib/client/imageUrls";
import styled, { css } from "src/lib/styles/css";
import { toNumber } from "lodash";
import NextImage from "next/image";
import type { ImageHotspot } from 'sanity';

const GLOBAL_POSTER_ASPECT = 271 / 384;

//#region [Props]
type CmsImageFromAssetRefProps = {
	assetRef: string;
	hotspot?: ImageHotspot;
} & BaseCmsImageProps;

//#endregion

//#region [Component]
export default function CmsImageForAssetRef({ assetRef, alt, className, sizes, width, height, priority = false, unoptimized = false, crop = false, isPosterAspect = false, nonPosterAspect, hotspot, applyAspectRatio = false }: CmsImageFromAssetRefProps) {
	if (!assetRef) {
		return null;
	}

	const size = getOrgSize(assetRef);
	const aspect = !isPosterAspect && nonPosterAspect ? nonPosterAspect : size.aspect;
	const w = toNumber(width ?? size.width);
	let h = Math.round(toNumber(height ?? w / aspect));
	let imgSrc = assetRef;
	if (unoptimized) {
		let imgBuilder = urlForAsset(assetRef).auto("format");
		if (isPosterAspect) {
			h = Math.floor(w / GLOBAL_POSTER_ASPECT);
		}
		imgBuilder = imgBuilder.width(w).height(h);
		imgBuilder = doCrop(imgBuilder, hotspot);
		imgSrc = imgBuilder.url();
	}
	return <SNextImage src={imgSrc}
		alt={alt}
		priority={priority}
		className={className}
		sizes={sizes}
		width={w}
		height={h}

		loader={getImageLoader(crop, isPosterAspect ? GLOBAL_POSTER_ASPECT : aspect, hotspot)}
		unoptimized={unoptimized}
		$aspectRatio={applyAspectRatio ? `${w} / ${h}` : undefined}
	/>
}
//#endregion



//#region [Styles]
type SNextImageProps = {
	$aspectRatio?: string;
}

const SNextImage = styled(NextImage) <SNextImageProps>`
	${props => props.$aspectRatio ? css`
		aspect-ratio: ${props.$aspectRatio};
	` : ""}
`;
//#endregion