"use client"
import styled from 'src/lib/styles/css';
import { ReactNode, useEffect } from 'react';
//#region [Styles]
const SDiv100Vh = styled.div`
	padding: 0;
 	margin: 0;
 	height: var(--doc-height, 100vh);
	@supports(height: 100dvh) {
		height: 100dvh;
	}
`;
//#endregion

//#region [Props]
type Div100VhProps = {
	className?: string;
	children: ReactNode;
    onClick?: () => void;
};
//#endregion

/**
 * https://dev.to/nirazanbasnet/dont-use-100vh-for-mobile-responsive-3o97
 */

//#region [Component]
export default function Div100Vh({ className, children, onClick }: Div100VhProps) {
	useEffect(() => {
		if (!CSS.supports("height", "100dvh")) {
			window?.addEventListener("resize", resizeListener);
			return () => {
				window?.removeEventListener("resize", resizeListener)
			}
		}

	}, []);
	return <SDiv100Vh className={className} onClick={onClick}>{children}</SDiv100Vh>;
}
//#endregion

const resizeListener = (event: UIEvent) => {
	const doc = document?.documentElement;
	doc?.style?.setProperty('--doc-height', `${window?.innerHeight}px`);
};