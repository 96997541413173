import { ImageVersionType } from "src/lib/movieinfo/movieinfotypes";
import NextImage from "next/image";

//#region [Props]
type ImageProps = {
	image: ImageVersionType[] | ImageVersionType | string;
	alt: string;
	className?: string;
	sizes?: string;
	unoptimized?: boolean;
};

//#endregion

//#region [Component]
/**
 * DO NOT USE FOR SANITY IMAGES
 */
export default function Image({ image, alt, sizes, className, unoptimized = false }: ImageProps) {
	let src: string | undefined = undefined;

	if (Array.isArray(image)) {
		// When receiving an array of image urls, we use the urls as-is
		const srcSet = image.map(i => `${i.url} ${i.width}w`);
		// eslint-disable-next-line @next/next/no-img-element
		return <img alt={alt} srcSet={srcSet.join(",")} sizes={sizes} className={className} />;
	} else if (typeof (image) === "string") {
		src = image;
	} else {
		src = image.url;
	}

	if (IS_TMDB_IMAGE.test(src)) {
		// TMDB image
		// Use the image as-is
		// NOTE: TMDB has specific image sizes for posters and wide images
		// eslint-disable-next-line @next/next/no-img-element
		return <img alt={alt} src={src} className={className} />
	} else if (IS_LEGACY_FILMWEB_IMAGE.test(src)) {
		// Legacy filmweb image from Escenic
		// TODO: remove when migrated
		// eslint-disable-next-line @next/next/no-img-element
		return <img alt={alt} src={src} className={className} />
	}

	return <NextImage src={src!} alt={alt} className={className} fill={!unoptimized} unoptimized={unoptimized} />;
}
//#endregion


//#region [Other]
const IS_TMDB_IMAGE = /image\.tmdb\.org/gi;
const IS_LEGACY_FILMWEB_IMAGE = /article(?:\d+)/gi

//#endregion

//#region [Other]

/*
const tmdbImageLoader: ImageLoader = (p) => {

	 Image urls look like this:
	 https://image.tmdb.org/t/p/w92/bk8LyaMqUtaQ9hUShuvFznQYQKR.jpg,
	 https://image.tmdb.org/t/p/w154/bk8LyaMqUtaQ9hUShuvFznQYQKR.jpg,
	 https://image.tmdb.org/t/p/w185/bk8LyaMqUtaQ9hUShuvFznQYQKR.jpg,
	 https://image.tmdb.org/t/p/w342/bk8LyaMqUtaQ9hUShuvFznQYQKR.jpg,
	 https://image.tmdb.org/t/p/w500/bk8LyaMqUtaQ9hUShuvFznQYQKR.jpg

}*/
//#endregion