
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { usePrevious } from '@react-hooks-library/core';

import useUserApi from "src/lib/filmwebid/useUserApi";
import { useUserContext } from "src/lib/contexts/UserContext";
//import { useWhatChanged } from '@simbathesailor/use-what-changed';

type UseWatchListArgs = {
	edi?: string | null;
	streamingId?: number | null;
	onComplete?: () => void;
}

export default function useWatchlist({ edi, streamingId, onComplete }: UseWatchListArgs): [Dispatch<SetStateAction<boolean | null>>, boolean] {
	const userContext = useUserContext();
	const fetcher = useUserApi();
	const [isWorking, setIsWorking] = useState<boolean>(false);
	const [watchlist, setWatchlist] = useState<boolean | null>(null);

	const prevEdi = usePrevious(edi);
	const prevStreamingId = usePrevious(streamingId);



	useEffect(() => {
		// reset the hook if the edi or streamingId changes
		if (edi !== prevEdi || streamingId !== prevStreamingId) {
			setWatchlist(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edi, streamingId]);

	//useWhatChanged([watchlist, edi, streamingId, onComplete], "watchlist, edi, streamingId, onComplete");

	useEffect(() => {
		if (watchlist !== null) {
			setIsWorking(true);
			userContext.updateWatchlistIdList({ edi, streamingId }, !watchlist);
			const _do = async () => {
				if (watchlist) {
					await fetcher("/api/Watchlist", { method: "POST", allowAnon: false, body: { EDI: edi ?? null, streamingId: streamingId ?? null, source: window?.location?.hostname ?? null } })
				} else {
					await fetcher("/api/Watchlist", { method: "DELETE", allowAnon: false, body: { edi: edi ?? null, streamingId: streamingId ?? null } })
				}

				setIsWorking(false);
				onComplete?.();
			};
			_do();

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchlist]);


	return [setWatchlist, isWorking];
}