import fadein from "src/components/styles/fadein";
import theme from "src/components/themes/DefaultTheme";
import { css } from "src/lib/styles/css";


const CSSPoster = css`
	position: relative;
	animation: ${fadein} 1s;
	flex: 0 0 auto;

	/*width: ${(100 / 3) - (60 / theme.maxContentWidth) * 100}%;

	@media ${theme.mq.desktop} {
		width: ${(100 / 6) - (60 / theme.maxContentWidth) * 100}%;
	}*/

	a {
		color: var(--textcolor);
		text-decoration: none;
	}

	&:hover a {
		text-decoration: underline;
	}

	img {
		display: block;
	}

	h1 {
		font-size: 0.8rem;
		color: var(--textcolor);
		text-align: center;
		white-space: nowrap;
		font-weight: normal;
		margin-top: 3px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export default CSSPoster;

export const CSSPosterWrapper = css`
	position: relative;
	display: block;
	overflow: hidden;
	border-radius: 4px;
`;

export const CSSPosterSizer = css`
	display: block;
	aspect-ratio: 1 / ${theme.posterAspectRatio};
	/*height: 0;
	padding-bottom: ${theme.posterAspectRatio * 100}%;*/
	border-radius: 4px;
	overflow: hidden;
	box-sizing: border-box;
`;

export type SPosterMissingProps = {
	$isPosterMissing?: boolean;
	$showMovieTitle?: boolean;
}

export const CSSPosterMissing = css`
	background: ${theme.missingPoster};
	position: relative;

	&::after {
		content: attr(data-postermissingtext);
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
		text-align: center;
		font-size: 0.8rem;
		opacity: 0.8;
		color: var(--buttontextcolor);
	}
`;

export const CSSPosterMissingMovieTitle = css`
	&::before {
		content: attr(data-movietitle);
		position: absolute;
		bottom: calc(50%);
		left: 0;
		padding-bottom: 0.5rem;
		width: 100%;
		text-align: center;
		font-size: 1rem;
		color: var(--buttontextcolor);
	}
`;