import styled from "src/lib/styles/css";
//#region [Styles]
const SFooterHeading = styled.div`
	font-size: 24px;
	font-weight: bold;
	text-align: center;
	padding-bottom: 5px;
`;
//#endregion

//#region [Props]
type FooterHeadingProps = {
	children: React.ReactNode;
};
//#endregion

//#region [Component]
export default function FooterHeading({ children }: FooterHeadingProps) {
	return <SFooterHeading>{children}</SFooterHeading>;
}
//#endregion