
import { UserContextType, useUserContext } from "src/lib/contexts/UserContext";
import { MovieInfoQuery } from "src/lib/movieinfo/movieinfotypes";
import { envConfig } from "src/lib/client/envConfig";
import { useCallback } from "react";

export type FetcherContextState = Pick<UserContextType, "isAuthenticated" | "doLogin" | "getAuthHeader" | "refreshAccessToken">;

export interface IFetcher {
	method: string;
	body?: any;
	signal?: AbortSignal;
	allowAnon: boolean;
	params?: any;
	userContextState?: FetcherContextState;
	skipRefreshToken?: boolean;
}

export default function useUserApi(withFilminfo = false) {
	const authContext = useUserContext();

	// NOTE: userContextState is for use in UserContext ONLY
	const fetcher = useCallback(async (path: string, { method, body, signal, allowAnon, params, userContextState, skipRefreshToken }: IFetcher = { method: "GET", allowAnon: true, skipRefreshToken: false }) => {
		const { isAuthenticated, doLogin, getAuthHeader, refreshAccessToken } = userContextState ?? authContext;
		//console.debug("skipRefreshToken", path, skipRefreshToken);
		if (!allowAnon && !isAuthenticated) {
			doLogin();
		}

		const init = createRequest(isAuthenticated, getAuthHeader, method, allowAnon, body, signal);

		let _p = path;
		if (params) {
			_p = `${_p}?${new URLSearchParams(params).toString()}`;
		}
		const base = withFilminfo ? envConfig.NEXT_PUBLIC_FILMINFO_ENDPOINT : envConfig.NEXT_PUBLIC_FILMWEBIDAPI_BASE;
		const response = await fetch(`${base}${_p}`, init);
		if (response.ok) {
			//console.debug("Initial request ok");
			if (withFilminfo) {
				let jsonResp = await response.json();
				let data = jsonResp?.data;
				let filminfoStatus = checkFilminfoStatus(data);
				if (filminfoStatus >= 200 && filminfoStatus < 300) {
					return jsonResp;
				} else if (filminfoStatus === 401 && skipRefreshToken !== true) {
					//console.debug("Initial filminfo request not authenticated");
					const response = await refreshAndRetry(isAuthenticated, getAuthHeader, refreshAccessToken, doLogin, base, method, _p, allowAnon, body, signal);
					if (response) {
						// TODO: What to do if it fails again?
						let jsonResp = await response.json();
						let data = jsonResp?.data;
						filminfoStatus = checkFilminfoStatus(data);
						if (filminfoStatus >= 200 && filminfoStatus < 300) {
							return jsonResp;
						}
					}
				}
			} else {
				const contentType = response.headers.get('content-type');
				if (contentType && contentType.indexOf('application/json') !== -1) {
					return await response.json();
				}
				return; // response was not json
			}
		} else if (isAuthenticated && response.status === 401 && skipRefreshToken !== true) {
			//console.debug("Initial request not authenticated");
			// refresh the token. If refresh fails, you will be sent to login
			const response = await refreshAndRetry(isAuthenticated, getAuthHeader, refreshAccessToken, doLogin, base, method, _p, allowAnon, body, signal);
			if (response?.ok) {
				//console.debug("Re-authenticated request successful");
				const contentType = response.headers.get('content-type');
				if (contentType && contentType.indexOf('application/json') !== -1) {
					return await response.json();
				}
				return; // response was not json
			}
		} else if (response.status === 401) {
			if (!skipRefreshToken) {
				doLogin();
			}
		} else {
			throw new Error(`${response.status} : ${response.statusText}`)
		}
	}, [authContext, withFilminfo])

	return fetcher;
}

function createRequest(isAuthenticated: boolean, getAuthHeader: UserContextType["getAuthHeader"], method: string, allowAnon: boolean, body?: any, signal?: AbortSignal) {
	const _m = (method || "GET").toUpperCase();
	const _h = new Headers();
	_h.append('Content-Type', 'application/json');
	_h.append('Accept', 'application/json');
	let _creds = "omit";
	if (allowAnon !== true || isAuthenticated) {
		//console.debug("Adding auth headers");
		_h.append('Authorization', getAuthHeader())
		_creds = "include";
	}

	const init: any = {
		method: _m,
		mode: 'cors',
		cache: 'no-cache',
		credentials: _creds,
		headers: _h
	};
	if (body && _m !== "GET" && _m !== "HEAD") {
		init.body = JSON.stringify(body);
	}
	if (signal) {
		init.signal = signal;
	}

	return init;
}

async function refreshAndRetry(isAuthenticated: boolean, getAuthHeader: UserContextType["getAuthHeader"], refreshAccessToken: UserContextType["refreshAccessToken"], doLogin: UserContextType["doLogin"], base: string, method: string, path: string, allowAnon: boolean, body?: any, signal?: AbortSignal) {
	if (await refreshAccessToken()) {
		// retry the request with the new access token
		const initRetry = createRequest(isAuthenticated, getAuthHeader, method, allowAnon, body, signal);
		const response = await fetch(`${base}${path}`, initRetry);
		if (response.ok) {
			//console.debug("Re-authenticated request successful");
			return response;
		}
		// TODO: What to do if it still fails (dependent on status code)
	} else {
		doLogin();
	}
	return null;
}

const FILMINFO_QUERY_NAMES = [
	"getFavourites",
	"getAlerts",
	"getList",
	"getRatings",
	"getReviews",
	"getUserActivity",
	"getWatchlist"
];



// check the status field for all possible user queries. Only 200, 401 and 500 are relevant
function checkFilminfoStatus(data: MovieInfoQuery) {
	//console.group("checkFilminfoStatus");
	//console.debug("data", data);
	let worstStatusCode = -1;
	if (data?.userQuery) {
		const uq = data.userQuery;
		Object.getOwnPropertyNames(uq);
		//console.debug("userQuery", uq);
		worstStatusCode = FILMINFO_QUERY_NAMES.map(qn => ((uq as any)[qn])?.status?.code).filter(code => !!code).reduce((prevVal, currVal) => Math.max(prevVal, currVal), worstStatusCode);
	}
	//console.debug("Worst status from filminfo", worstStatusCode);
	//console.groupEnd();
	return worstStatusCode;
}