import { IMenuItem } from 'src/lib/types/Settings';
import { Dispatch } from 'react';
import { rgba } from 'polished';
import styled, { css } from "src/lib/styles/css";
import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import theme from 'src/components/themes/DefaultTheme';
import { IMenuAction } from 'src/components/menu/SearchAndDropdownMenu';


//#region [Props]
type DropDownMenuProps = {
	menuItems?: IMenuItem[];
	isVisible: boolean;
};
//#endregion

//#region [Component]
export default function DropDownMenu({ menuItems, isVisible }: DropDownMenuProps) {
	if ((menuItems?.length ?? 0) === 0) {
		return null;
	}
	return <SDropDownWrapper $isVisible={isVisible}>
		<SMenuItems>
			{menuItems?.map(mi => {
				let isEaster = false;
				if (mi.text.toLocaleLowerCase().includes("påske")) isEaster = true;
				return <SMenuItem key={mi._key} to={mi.url} $isEaster={isEaster}>{mi.text}</SMenuItem>
			})}
		</SMenuItems>
	</SDropDownWrapper>;
}
//#endregion

//#region [Styles]
const menuAnimationSpeed = 0.2;

type SDropDownWrapperProps = {
	$isVisible: boolean;
}

const SDropDownWrapper = styled.div<SDropDownWrapperProps>`
	position: absolute;
	height: fit-content;
	top: 55px;
	left: 0;
	right: 0;
	transform: scaleY(1);
	transform-origin: top;
	background-color: ${rgba(theme.mainColor, theme.generalAlpha)};
	color: ${theme.textColor};
	z-index: 102;
	overflow: hidden;
	transition: transform ${menuAnimationSpeed}s ease-in-out;
	bottom: 0;
	//backdrop-filter: blur(4px);
	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		bottom: auto;
	}

	${props => !props.$isVisible && css`
		transform: scaleY(0);
	`}
`;

const SMenuItems = styled.nav`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

type SMenuItemProps = {
	$isEaster: boolean;
}

const SMenuItem = styled(FilmwebLink) <SMenuItemProps>`
	width: 100%;
	text-align: center;
	padding: 1rem 0;
	font-size: 22px;
	font-weight: 700;
	text-decoration: none;
	color: ${theme.textColor};
	cursor: pointer;

	${props => props.$isEaster && css`
		color: ${theme.easter};
	`}
`;

//#endregion