import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg } from 'src/components/svg/SvgStyles';


export default function SomeInstagramSquareLogoSvg({ className }: StandardSvgProps) {
	return (
		<SDefaultSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.998 25.998">
            <defs>
                <linearGradient id="instasoc-media-gradient" x2="0" y2="1">
                    <stop offset="0%" stopColor="var(--gradient-start, #fff)" />
                    <stop offset="100%" stopColor="var(--gradient-end, #fff)" />
                </linearGradient>
            </defs>
			<path d="M2244.921,1365.356a9.6,9.6,0,0,1-.606,3.156,6.633,6.633,0,0,1-3.8,3.8,9.6,9.6,0,0,1-3.155.607c-1.387.061-1.825.076-5.358.076s-3.971-.015-5.357-.077a9.594,9.594,0,0,1-3.155-.606,6.632,6.632,0,0,1-3.8-3.8,9.588,9.588,0,0,1-.607-3.155c-.061-1.386-.076-1.825-.076-5.358s.015-3.971.076-5.358a9.588,9.588,0,0,1,.607-3.155,6.632,6.632,0,0,1,3.8-3.8,9.6,9.6,0,0,1,3.155-.607c1.387-.061,1.825-.076,5.357-.076s3.971.015,5.358.077a9.6,9.6,0,0,1,3.155.607,6.633,6.633,0,0,1,3.8,3.8,9.6,9.6,0,0,1,.606,3.155c.062,1.386.077,1.825.077,5.358S2244.983,1363.97,2244.921,1365.356Zm-2.34-10.608a7.278,7.278,0,0,0-.448-2.416,4.323,4.323,0,0,0-2.467-2.467,7.277,7.277,0,0,0-2.417-.448c-1.366-.061-1.779-.076-5.25-.076s-3.884.015-5.25.076a7.278,7.278,0,0,0-2.416.448,4.323,4.323,0,0,0-2.467,2.467,7.272,7.272,0,0,0-.448,2.416c-.061,1.366-.076,1.779-.076,5.251s.015,3.884.076,5.251a7.272,7.272,0,0,0,.448,2.416,4.323,4.323,0,0,0,2.467,2.467,7.278,7.278,0,0,0,2.416.448c1.366.061,1.779.076,5.25.076s3.885-.015,5.25-.076a7.277,7.277,0,0,0,2.417-.449,4.323,4.323,0,0,0,2.467-2.467,7.272,7.272,0,0,0,.448-2.416c.062-1.366.077-1.779.077-5.251S2242.643,1356.115,2242.582,1354.748Zm-3.645-.127a1.56,1.56,0,1,1,1.56-1.56A1.56,1.56,0,0,1,2238.937,1354.621ZM2232,1366.672a6.673,6.673,0,1,1,6.673-6.673,6.673,6.673,0,0,1-6.673,6.673Zm0-11.006a4.333,4.333,0,1,0,4.333,4.333,4.333,4.333,0,0,0-4.333-4.333Z" transform="translate(-2219 -1347)" />
		</SDefaultSvg>
	);
}