import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import CheckboxSvg from 'src/components/svg/CheckboxSvg';
import { useState } from 'react';
import styled, { css } from "src/lib/styles/css";
import theme from 'src/components/themes/DefaultTheme';
//#region [Styles]
const SNewsletterSignup = styled.div`
	display: flex;
	flex-direction: column;
`;

const CSSLine = css`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	width: 100%;

	> * {
		flex: 0 0 auto;
	}
`;

const SForm = styled.form`
	${CSSLine}
`;

const SEmailInput = styled.input`
	margin-top: 15px;
	background-color: ${theme.palette.hvitHovedfarge};
	border: none;
	border-radius: 8px 0 0 8px;
	height: 55px;
	padding: 10px 20px 10px 20px;
	min-width: 210px;
	font-size: 18px;
	flex: 1 1 auto;
	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		margin-top: 25px;
	}
`;

const SSubmitButton = styled.button`
	margin-top: 15px;
	background-color: var(--backgroundcolor);
	border: none;
	height: 55px;
	border-radius: 0 30px 30px 0;
	color: var(--textcolor);
	font-size: 16px;
	padding: 10px 15px 12.5px 15px;
	min-width: 100px;
	cursor: pointer;
	flex: 0 0 100px;

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		margin-top: 25px;
		min-width: 122px;
	}
`;

const SPersonvern = styled.div`
	${CSSLine}

	padding-top: 15px;

`;

const SCheckboxSvg = styled(CheckboxSvg)`
	height: 26px;
	width: 26px;
	margin-right: 10px;

	fill: var(--buttontextcolor);
`;

const SLabel = styled.div`
	color: var(--buttontextcolor);
`;

const SPersonvernLink = styled(FilmwebLink)`
	color: var(--buttontextcolor);
	&:visited {
		color: var(--buttontextcolor);
	}
`;

const SError = styled.div`
	color: red;
	text-align: center;
`;
//#endregion

//#region [Props]
type NewsletterSignupProps = {
	isStreaming: boolean;
};
//#endregion

//#region [Component]
export default function NewsletterSignup({ isStreaming }: NewsletterSignupProps) {
	const [isChecked, setIsChecked] = useState(false);
	const [isFull, setIsFull] = useState(false);

	return <SNewsletterSignup>
		<div>
			<SForm action="https://filmweb.us6.list-manage.com/subscribe/post?u=e0cead56b303960f1671cd15c&id=2ced82373a&f_id=00fbc2e1f0" method="post" name="mc-embedded-subscribe-form" noValidate target="_blank">
				<SEmailInput type="email" placeholder="Din epostadresse" required name="EMAIL" id="mce-EMAIL" aria-labelledby="newsletterLabel" onChange={() => setIsFull(true)} />
				<div aria-hidden="true" style={{ position: "absolute", left: "-5000px" }}>
					<input name="b_e0cead56b303960f1671cd15c_2ced82373a" tabIndex={-1} type="text" defaultValue="" />
				</div>
				<div className="mc-field-group input-group" style={{ display: "none" }} aria-hidden="true">
					<input type="checkbox" value="8" name="group[8][8]" id="mce-group[8]-8-0" checked={!isStreaming} readOnly />
					<input type="checkbox" value="16" name="group[8][16]" id="mce-group[8]-8-1" checked={isStreaming} readOnly/>
				</div>
				<SSubmitButton id="mc-embedded-subscribe" name="subscribe" type="submit" disabled={!isChecked}>Send</SSubmitButton>
			</SForm>
		</div>

		<SPersonvern onClick={() => setIsChecked(!isChecked)}>
			<SCheckboxSvg isChecked={isChecked} />
			<SLabel >Godta <SPersonvernLink to="https://skynet.filmweb.no/filmwebforms/form/displayform?id=20" rel="noopener noreferrer" target="_blank">personvernerklæringen</SPersonvernLink></SLabel>
		</SPersonvern>
		{!isChecked && isFull && <SError>Du må godta personvernerklæringen</SError>}
	</SNewsletterSignup>;
}


const REGULAR_NEWSLETTER_ID = "565147c29b";
const STREAMING_NEWSLETTER_ID = "243fb0d50f";
//#endregion