import NewsletterSignup from 'src/components/newslettersignup/NewsletterSignup';
import FooterHeading from 'src/footer/FooterHeading';
import { useRouter } from 'next/router';
import styled from "src/lib/styles/css";
//#region [Styles]
const SNewsletter = styled.div`
	padding-top: 21px;
`;
//#endregion

//#region [Props]
type NewsletterProps = {

};
//#endregion

//#region [Component]
export default function Newsletter({ }: NewsletterProps) {
	const router = useRouter();

	const isStreaming = router.route.startsWith("/streamingguide");

	return <SNewsletter>
		<FooterHeading>{isStreaming ? "Meld deg på Streamingguidens nyhetsbrev" : "Meld deg på vårt nyhetsbrev"}</FooterHeading>
		<NewsletterSignup isStreaming={isStreaming} />
	</SNewsletter>;
}
//#endregion