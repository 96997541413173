import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg, SFilledPath } from 'src/components/svg/SvgStyles';

//#region [Props]
type ChairSvgProps = {
	isFilled?: boolean;
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function ChairSvg({ className, isFilled = false }: ChairSvgProps) {
	return (<SDefaultSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 216">
		<SFilledPath $isFilled={isFilled}
			d="m 205.2,216 h -21.6 c -5.96468,0 -10.8,-4.83532 -10.8,-10.8 v -8.64 h 12.96 c 5.96468,0 10.8,-4.83532 10.8,-10.8 v -25.92 c 0,-5.96468 -4.83532,-10.8 -10.8,-10.8 H 172.8 v -30.781 c 0.001,-5.66426 4.59174,-10.25624 10.256,-10.259 H 205.2 c 5.96468,0 10.8,4.83532 10.8,10.8 v 86.4 c 0,5.96468 -4.83532,10.8 -10.8,10.8 z M 32.4,216 H 10.8 C 4.8353247,216 0,211.16468 0,205.2 V 118.8 C 0,112.83532 4.8353247,108 10.8,108 h 22.145 c 5.66426,0.003 10.254897,4.59474 10.256,10.259 v 30.781 h -12.96 c -5.964675,0 -10.8,4.83532 -10.8,10.8 v 25.92 c 0,5.96468 4.835325,10.8 10.8,10.8 h 12.96 v 8.64 c 0,5.96507 -4.835934,10.80055 -10.801,10.8 z m -1.617,-21.6 c -5.070231,-5.5e-4 -9.180792,-4.10977 -9.183,-9.18 v 0 -24.832 c 0.0017,-5.07062 4.112379,-9.18045 9.183,-9.181 h 154.433 c 5.07124,-5.5e-4 9.1829,4.10976 9.184,9.181 v 24.833 c -0.002,5.07046 -4.11354,9.17955 -9.184,9.179 z M 170.64,149.04 H 45.36 v -32.4 c 0,-5.96468 -4.835325,-10.8 -10.8,-10.8 H 21.6 V 21.6 C 21.6,9.6706494 31.270649,0 43.2,0 h 129.6 c 11.92935,0 21.6,9.6706494 21.6,21.6 v 84.24 h -12.96 c -5.96468,0 -10.8,4.83532 -10.8,10.8 z"
		/>
		<SFilledPath $isFilled={!isFilled}
			d="m 205.2,213.465 a 8.266,8.266 0 0 0 8.266,-8.265 v -86.4 a 8.266,8.266 0 0 0 -8.266,-8.266 h -22.142 a 7.727,7.727 0 0 0 -7.723,7.725 v 28.246 h 10.425 a 13.326,13.326 0 0 1 13.34,13.335 v 25.92 a 13.327,13.327 0 0 1 -13.335,13.334 H 175.34 v 6.106 a 8.265,8.265 0 0 0 8.265,8.265 h 21.6 m -172.8,0 A 8.266,8.266 0 0 0 40.67,205.2 v -6.106 H 30.24 A 13.327,13.327 0 0 1 16.906,185.759 V 159.84 A 13.326,13.326 0 0 1 30.24,146.506 H 40.666 V 118.26 a 7.727,7.727 0 0 0 -7.721,-7.725 H 10.8 a 8.266,8.266 0 0 0 -8.265,8.266 v 86.4 a 8.265,8.265 0 0 0 8.265,8.265 h 21.6 m -1.617,-21.6 h 154.433 a 6.649,6.649 0 0 0 6.65,-6.645 v -24.832 a 6.649,6.649 0 0 0 -6.65,-6.647 H 30.783 a 6.649,6.649 0 0 0 -6.649,6.646 v 24.831 a 6.65,6.65 0 0 0 6.65,6.647 m 137.322,-45.36 v -29.867 a 13.326,13.326 0 0 1 13.334,-13.334 h 10.426 V 21.6 A 19.049,19.049 0 0 0 172.8,2.535 H 43.2 A 19.048,19.048 0 0 0 24.134,21.601 v 81.705 H 34.56 a 13.325,13.325 0 0 1 13.334,13.335 v 29.865 H 168.106 M 205.2,216 h -21.6 a 10.8,10.8 0 0 1 -10.8,-10.8 v -8.64 h 12.96 a 10.8,10.8 0 0 0 10.8,-10.8 v -25.92 a 10.8,10.8 0 0 0 -10.8,-10.8 H 172.8 V 118.259 A 10.261,10.261 0 0 1 183.056,108 H 205.2 a 10.8,10.8 0 0 1 10.8,10.8 v 86.4 A 10.8,10.8 0 0 1 205.2,216 Z M 32.4,216 H 10.8 A 10.8,10.8 0 0 1 0,205.2 V 118.8 A 10.8,10.8 0 0 1 10.8,108 h 22.145 a 10.261,10.261 0 0 1 10.256,10.259 v 30.781 h -12.96 a 10.8,10.8 0 0 0 -10.8,10.8 v 25.92 a 10.8,10.8 0 0 0 10.8,10.8 h 12.96 v 8.64 A 10.8,10.8 0 0 1 32.4,216 Z M 30.783,194.4 A 9.184,9.184 0 0 1 21.6,185.22 v 0 -24.832 a 9.184,9.184 0 0 1 9.183,-9.181 h 154.433 a 9.183,9.183 0 0 1 9.184,9.181 v 24.833 a 9.183,9.183 0 0 1 -9.184,9.179 z M 170.64,149.04 H 45.36 v -32.4 a 10.8,10.8 0 0 0 -10.8,-10.8 H 21.6 V 21.6 A 21.6,21.6 0 0 1 43.2,0 h 129.6 a 21.6,21.6 0 0 1 21.6,21.6 v 84.24 h -12.96 a 10.8,10.8 0 0 0 -10.8,10.8 z M 43.2,118.259 Z m 0,0 z"
		/>
	</SDefaultSvg>)
}
//#endregion