import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg } from 'src/components/svg/SvgStyles';


export default function TMDBSvg({ className }: StandardSvgProps) {
	return (
		<SDefaultSvg className={className} xmlns="http://www.w3.org/2000/svg" width="75.809" height="9.862" viewBox="0 0 75.809 9.862">
			<defs>
				<linearGradient id="tmdbGradient" y1="0.499" x2="1" y2="0.499" gradientUnits="objectBoundingBox">
					<stop offset="0" stopColor="#90cea1" />
					<stop offset="0.56" stopColor="#3cbec9" />
					<stop offset="1" stopColor="#00b3e5" />
				</linearGradient>
			</defs>
			<path className={className} style={{ fill: "url(#tmdbGradient)" }} d="M53.193,9.807H70.91a4.9,4.9,0,0,0,4.9-4.9h0A4.9,4.9,0,0,0,70.91,0H53.193a4.9,4.9,0,0,0-4.9,4.908h0A4.9,4.9,0,0,0,53.193,9.807ZM2.8,9.821H4.963v-7.9h2.8V0H0V1.913H2.8Zm7.791,0h2.163V2.287h.028l2.481,7.528h1.664l2.565-7.528h.028V9.815h2.163V0H18.424L16.151,6.4h-.028L13.863,0H10.591ZM24.715.033h3.244A9.305,9.305,0,0,1,30.2.311a5.135,5.135,0,0,1,1.849.854A4.184,4.184,0,0,1,33.3,2.695a5.129,5.129,0,0,1,.463,2.287,4.689,4.689,0,0,1-.449,2.1A4.519,4.519,0,0,1,32.1,8.609a5.335,5.335,0,0,1-1.761.934,6.8,6.8,0,0,1-2.093.319H24.715Zm2.163,7.819h1.109A6.006,6.006,0,0,0,29.373,7.7,2.933,2.933,0,0,0,30.5,7.209a2.42,2.42,0,0,0,.743-.929,3.3,3.3,0,0,0,.277-1.408,2.737,2.737,0,0,0-.277-1.253,2.543,2.543,0,0,0-.729-.882,3.219,3.219,0,0,0-1.062-.518,4.73,4.73,0,0,0-1.3-.175H26.878ZM36.9.033h3.66a9.114,9.114,0,0,1,1.284.091A3.51,3.51,0,0,1,43,.485a2.2,2.2,0,0,1,.832.754,2.312,2.312,0,0,1,.319,1.289,2.074,2.074,0,0,1-.463,1.386A2.531,2.531,0,0,1,42.46,4.7v.017a2.85,2.85,0,0,1,.882.277,2.36,2.36,0,0,1,.679.513,2.16,2.16,0,0,1,.435.726,2.54,2.54,0,0,1,.153.887,2.362,2.362,0,0,1-.333,1.3,2.584,2.584,0,0,1-.86.832,3.71,3.71,0,0,1-1.184.457,6.238,6.238,0,0,1-1.311.139H36.9Zm2.163,3.923H40.63a2.121,2.121,0,0,0,.494-.055,1.325,1.325,0,0,0,.435-.18.951.951,0,0,0,.313-.333,1.006,1.006,0,0,0,.116-.5.915.915,0,0,0-.122-.5.948.948,0,0,0-.341-.313,1.683,1.683,0,0,0-.491-.158,2.745,2.745,0,0,0-.513-.05H39.052Zm0,4.062H41a2.293,2.293,0,0,0,.507-.055,1.3,1.3,0,0,0,.463-.194,1.09,1.09,0,0,0,.341-.36,1.054,1.054,0,0,0,.13-.541.876.876,0,0,0-.172-.555,1.109,1.109,0,0,0-.438-.327,2.282,2.282,0,0,0-.555-.152,4.192,4.192,0,0,0-.568-.042H39.077Z" transform="translate(0 0)" />
		</SDefaultSvg>
	);
}