import { StandardSvgProps } from "src/components/svg/StandardSvgProps";
import { SDefaultSvg } from 'src/components/svg/SvgStyles';
import styled from 'src/lib/styles/css';
//#region [Styles]
const SCloseSvg = styled(SDefaultSvg)`
	rect {
		fill:var(--TEXTCOLOR_OVERRIDE, var(--buttonactivecolor));
	}
`;
//#endregion

//#region [Component]
export default function CloseSvg({ className }: StandardSvgProps) {
	return <SCloseSvg className={className} width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
		<g transform="translate(-484.368 -1129.957) rotate(45)" >
			<rect width="3" height="21" rx="1.5" transform="translate(1152 446)" />
			<rect width="3" height="21" rx="1.5" transform="translate(1143 458) rotate(-90)"/>
		</g>
	</SCloseSvg>;
}
//#endregion