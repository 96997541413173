export default function getURLParameter(name: string): string | string[] | null {
	if (typeof window === "undefined") {
		return null;
	}
	const re = new RegExp('[?&#]' + name + '=([^&#]*)', 'g');
	let results: string[] = [];
	let match;
	while ((match = re.exec(window.location.href))) {
		results.push(match[1]);
	}
	if (!results.length) {
		return null;
	}
	return results.length === 1 ? results[0] : results;
}