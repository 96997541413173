import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg } from 'src/components/svg/SvgStyles';
import styled from "src/lib/styles/css";


export default function LocationSvg({ className }: StandardSvgProps) {
	return (
		<SLocationSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.872 15.576">
			<path d="M271.239,1020.964c-1.095,2.133-4.331,7.056-4.468,7.263a.427.427,0,0,1-.706,0c-.131-.209-3.379-5.125-4.468-7.266a5.536,5.536,0,0,1-.608-2.69,5.434,5.434,0,0,1,10.868,0A5.556,5.556,0,0,1,271.239,1020.964Zm-4.819-4.653a2,2,0,1,0,2.006,2A2,2,0,0,0,266.42,1016.311Z" transform="translate(-260.986 -1012.84)" />
		</SLocationSvg>
	);
}

//#region [Styles]
const SLocationSvg = styled(SDefaultSvg)`
	path {
		fill: var(--iconcolor);
	}
`;
//#endregion