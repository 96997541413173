import ChairSvg from 'src/components/svg/ChairSvg';
import theme from 'src/components/themes/DefaultTheme';
import styled, { css, keyframes } from "src/lib/styles/css";
//#region [Styles]
type SLoadingIndicatorProps = {
	$position: "AbsoluteCenter" | "TopCenter" | "InlineCenter";
	$size: "large" | "small";
}

const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

const pulse = keyframes`
	0% {
		transform: scale(1);
	}

	25% {
		transform: scale(0.5);
	}

	75% {
		transform: scale(0.5);
	}

	100% {
		transform: scale(1);
	}
`;

const SLoadingIndicator = styled.div<SLoadingIndicatorProps>`
	display: flex;
	gap: 10%;
	--gr1Rotation: 150deg;
	animation: ${gradient} 8s infinite;
	background: ${theme.palette.buttonGradient1};
	background-size: 400% 400%;
	padding: 10px 20px;

	justify-content: space-between;

	max-width: 200px;

	overflow: hidden;
	border-radius: 25px;

	${props => props.$position === "AbsoluteCenter" && css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	`}

	${props => props.$position === "TopCenter" && css`
		position: absolute;
		top: 100px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
	`}

	${props => props.$position === "InlineCenter" && css`
		margin: 0 auto;
	`}

	${props => props.$size ==="small" && css`
		border-radius: 50%;
		width: 20px;
		height: 20px;
		box-sizing: content-box;
		padding: 5px;
	`}

	${props => props.$size ==="large" && css`
		width: 200px;
		height: 50px;
	`}

`;

type SChairSvgProps = {
	$speed: number;
}

const SChairSvg = styled(ChairSvg)<SChairSvgProps>`
	flex: 1 0 auto;

	&:first-child {
		animation: ${pulse} ${props => `${props.$speed}s`} infinite;
	}

	&:nth-child(2) {
		animation: ${pulse} ${props => `${props.$speed}s`} infinite ${props => `${props.$speed / 8}s`};
	}

	&:nth-child(3) {
		animation: ${pulse} ${props => `${props.$speed}s`} infinite ${props => `${props.$speed / 4}s`};
	}

	path {
		fill: ${theme.palette.lillaMørkHovedfarge};
	}
`

//#endregion

//#region [Props]
type LoadingIndicatorProps = {
	className?: string;
	position?: "AbsoluteCenter" | "TopCenter" | "InlineCenter";
	size?: "large" | "small";
	label?: string;
};
//#endregion

//#region [Component]
export default function LoadingIndicator({ className, position = "AbsoluteCenter", size = "large", label }: LoadingIndicatorProps) {
	return <SLoadingIndicator $position={position} $size={size} className={className} aria-label={label}>
		<SChairSvg $speed={2} isFilled />
		{size === 'large' && <>
			<SChairSvg $speed={2} isFilled/>
			<SChairSvg $speed={2} isFilled/>
		</>}
	</SLoadingIndicator>;
}
//#endregion