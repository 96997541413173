import ButtonLink from 'src/components/buttonlink/ButtonLink';
import ISettings from 'src/lib/types/Settings';
import styled from "src/lib/styles/css";
import theme from 'src/components/themes/DefaultTheme';
//#region [Styles]
const SKGKContainer = styled.div`
	margin-left: auto;
	padding-left: 20px;
	@media ${theme.mq.mobile} {
		display: none;
	}
`;

const SKgkButtonLink = styled(ButtonLink)`
	font-weight: bold;
	white-space: nowrap;
	background: ${theme.palette.buttonGradient2};
`
//#endregion

//#region [Props]
type PromoLinksKgkProps = {
	settings: ISettings;
};
//#endregion

//#region [Component]
export default function PromoLinksKgk({ settings }: PromoLinksKgkProps) {
	if (settings?.showKgkPurchaseLink) {
		return <SKGKContainer>
			<SKgkButtonLink href='https://kinogavekort.no/?utm_source=Filmweb&utm_medium=meny' inverse target='_blank'>Kjøp kinogavekort</SKgkButtonLink>
		</SKGKContainer>;
	}
	return null;
}
//#endregion