import { IMenuItem } from 'src/lib/types/Settings';
import Link from 'next/link';
import styled from "src/lib/styles/css";
import theme from 'src/components/themes/DefaultTheme';

//#region [Styles]
type SHotlinkProps = {
	$isEaster: boolean;
};

const SHotlink = styled.div<SHotlinkProps>`
	display: none;

	// custom breakpoint to make it work on ipad
	@media screen and (min-width: 1100px) {
		//@media #{settings.$desktop} {
		//@media (orientation: landscape) and (min-width: 1074px) {
		display: block;
		font-size: 15px;
		color: ${theme.textColor};
		padding-left: 30px;
		font-weight: 700;
		white-space: nowrap;

		a {
			color: ${props => props.$isEaster ? theme.easter : theme.textColor};
			text-decoration: none;


		}
	}
`
//#endregion

//#region [Props]
type HotlinksProps = {
	hotlinks?: IMenuItem[];
};
//#endregion

//#region [Component]
export default function Hotlinks({ hotlinks }: HotlinksProps) {
	if ((hotlinks?.length ?? 0) === 0) {
		return null;
	}

	return <>
		{hotlinks!.map(hl => {
			const url = hl.url;
			const linktext = hl.text;
			let isEaster = false;
			if (linktext.toLocaleLowerCase().includes("påske")) isEaster = true;
			return (<SHotlink key={hl._key} $isEaster={isEaster}><Link href={url}>{linktext}</Link></SHotlink>);
		})}
	</>;
}
//#endregion