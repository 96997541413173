import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import Hotlinks from 'src/components/menu/Hotlinks';
import PromoLinksKgk from 'src/components/menu/PromoLinksKgk';
import SearchAndDropdownMenu from 'src/components/menu/SearchAndDropdownMenu';
import FilmwebLogoSvg from 'src/components/svg/FilmwebLogoSvg';
import ISettings from 'src/lib/types/Settings';
import { rgba } from "polished";
import styled, { css } from "src/lib/styles/css";
import theme from 'src/components/themes/DefaultTheme';

//#region [Props]
type MenuProps = {
	settings: ISettings;
};
//#endregion

//#region [Component]
export default function Menu({ settings }: MenuProps) {
	return <SMenuWrapper>
		<SLogoLink to='/' title='Til forsiden'>
			<FilmwebLogoSvg />
		</SLogoLink>
		<Hotlinks hotlinks={settings?.hotlinks}/>
		<PromoLinksKgk settings={settings}/>
		<SearchAndDropdownMenu menuData={settings?.mainMenu}/>
	</SMenuWrapper>;
}
//#endregion

//#region [Styles]
const SMenuWrapper = styled.nav`
	height: ${theme.topMenuHeight};
	background-color: ${rgba(theme.mainColor, theme.generalAlpha)};
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	//backdrop-filter: blur(4px);
	position: relative;
	z-index: 101;
`;

type SLogoLinkProps = {
	hideLogo?: boolean;
}

const SLogoLink = styled(FilmwebLink)<SLogoLinkProps>`
	padding: 0 10px;
	max-height: 25px;
	min-width: 144px;
	max-width: 144px;
	transition: max-width 0.6s, min-width 0.6s, transform 0.6s;
	flex: 0 0 auto;

	svg {
		max-height: 25px;
	}

	${props => props.hideLogo ? css`
		@media screen and ${theme.mq.mobile} {
			max-width: 0;
			min-width: 0;
			transform: translate(-100%, 0);
		}
	`: null}

`;


//#endregion