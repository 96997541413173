import { SButton } from 'src/components/button/Button';
import Link from 'next/link';
import styled from "src/lib/styles/css";
//#region [Styles]
const SButtonLink = styled(SButton)`
	text-decoration: none;
`;
//#endregion

//#region [Props]
type ButtonLinkProps = {
	className?: string;
	inverse?: boolean;
	isOutline?: boolean;
	children: React.ReactNode;
	href: string;
	target?: string;
	borderStyle?: "round" | "square";
};
//#endregion

//#region [Component]
export default function ButtonLink({ className, inverse = false, isOutline = false, href, children, target, borderStyle = "round" }: ButtonLinkProps) {
	return <Link href={href} legacyBehavior passHref target={target}>
		<SButtonLink as="a" $inverse={inverse} className={className} $isOutline={isOutline} $borderStyle={borderStyle}>
			{children}
		</SButtonLink>
	</Link>;
}
//#endregion