import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';

type SearchSvgProps = {
	useCustomGradient?: boolean;
} & StandardSvgProps;

export default function SearchSvg({ className, useCustomGradient = false }: SearchSvgProps) {
	return (
		<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.936 18.709">
			{useCustomGradient && <defs>
				<linearGradient id="linear-gradientSearch" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					<stop offset="0" stopColor="var(--custom-gradient-stop0)" />
					<stop offset="1" stopColor="var(--custom-gradient-stop1)" />
				</linearGradient>
			</defs>}
			<path d="M585.337,1114.867l5.228,5.165a1.358,1.358,0,0,1-.007,1.937,1.4,1.4,0,0,1-1.962.008l-5.228-5.165a7.336,7.336,0,0,1-9.221-.845,7.137,7.137,0,0,1,.035-10.178,7.348,7.348,0,0,1,10.3-.034A7.121,7.121,0,0,1,585.337,1114.867Zm-9.687-7.627a5.1,5.1,0,0,0-.026,7.27,5.249,5.249,0,0,0,7.358-.025,5.1,5.1,0,0,0,.026-7.27,5.249,5.249,0,0,0-7.358.025Z"
				transform="translate(-572.033 -1103.666)"
				fill={useCustomGradient ? "url(#linear-gradientSearch)" : undefined} />
		</svg>
	);
}