import styled from "src/lib/styles/css";

export const SDefaultSvg = styled.svg`
	path {
		fill: var(--ICONCOLOR_OVERRIDE, var(--iconcolor));
	}
`;

type SFilledPathProps = {
	$isFilled?: boolean;
}
export const SFilledPath = styled.path<SFilledPathProps>`
	transition: opacity 0.5s;

	opacity: ${props => props.$isFilled ? 1 : 0};
`