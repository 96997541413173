import { BaseCmsImageProps } from "src/components/images/BaseCmsImageProps";
import CmsImageForAssetRef from "src/components/images/CmsImageForAssetRef";

//#region [Props]
type CmsImageForUrlProps = {
	imageUrl: string;
} & BaseCmsImageProps;
//#endregion

// NOTE TO SELF: Do NOT use g flag when using "global" regexes like this!
const imgUrlRegExp = new RegExp(`^https://cdn.sanity.io/images/${process.env.NEXT_PUBLIC_SANITY_PROJECTID}/${process.env.NEXT_PUBLIC_SANITY_DATASET}/(.*?)\\.(.*?)(?:\\?(.*?))?$`, "i");

//#region [Component]
export function CmsImageForUrl({ imageUrl, ...rest }: CmsImageForUrlProps) {
	if (!imageUrl) {
		return null;
	}
	const res = imgUrlRegExp.exec(imageUrl);
	if (!res) {
		return null;
	}
	return <CmsImageForAssetRef assetRef={`image-${res[1]}-${res[2]}`} {...rest} />;
}
//#endregion