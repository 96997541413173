import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg, SFilledPath } from 'src/components/svg/SvgStyles';
import theme from 'src/components/themes/DefaultTheme';
import styled from "src/lib/styles/css";

//#region [Styles]
const SWatchlistSvg = styled(SDefaultSvg)`
	fill: none;

	path {
		fill: var(--ICONCOLOR_OVERRIDE, ${theme.palette.turkis2});
	}
`;

//#endregion

//#region [Props]
type WatchlistSvgProps = {
	selected?: boolean;
} & StandardSvgProps;

//#endregion

//#region [Component]
export default function WatchlistSvg({ className, selected = false }: WatchlistSvgProps) {
	return (
		<SWatchlistSvg className={className} xmlns="http://www.w3.org/2000/svg" width="17.095"
			height="22.936"
			viewBox="0 0 17.095 22.936">
			<SFilledPath $isFilled={!selected}
				d="m 14.251937,20.935 a 0.878,0.878 0 0 0 0.844,-0.91 V 4.835 A 2.742,2.742 0 0 0 12.464937,2 H 4.6319373 a 2.742,2.742 0 0 0 -2.631,2.835 v 15.191 a 0.879,0.879 0 0 0 0.845,0.91 h 0.31 a 0.813,0.813 0 0 0 0.6,-0.266 l 4.853,-5.231 4.8589997,5.231 a 0.812,0.812 0 0 0 0.6,0.266 h 0.185 m 0,2 h -0.185 a 2.823,2.823 0 0 1 -2.065,-0.908 l -3.3939997,-3.65 -3.392,3.653 a 2.825,2.825 0 0 1 -2.062,0.9 h -0.309 a 2.881,2.881 0 0 1 -2.845,-2.91 V 4.835 A 4.742,4.742 0 0 1 4.6319373,0 h 7.8319997 a 4.742,4.742 0 0 1 4.631,4.835 v 15.191 a 2.881,2.881 0 0 1 -2.843,2.909 z" />
			<SFilledPath $isFilled={selected}
				d="m 14.251937,20.935 c 0.484259,-0.01843 0.862013,-0.425725 0.844,-0.91 V 4.8350001 c 0.05238,-1.5079223 -1.123369,-2.7748297 -2.631,-2.835 h -7.833 c -1.5076313,0.06017 -2.683375,1.3270777 -2.631,2.835 V 20.026 c -0.017447,0.484443 0.3605936,0.891564 0.845,0.91 h 0.31 c 0.2284934,-4.12e-4 0.4462659,-0.09696 0.6,-0.266 l 4.8530003,-5.231 4.8589997,5.231 c 0.15366,0.169145 0.37148,0.265712 0.6,0.266 h 0.185" />
		</SWatchlistSvg>
	);
}
//#endregion