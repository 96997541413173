import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { useState } from "react";

//#region [Props]
type QueryClientWrapperProps = {
	children: React.ReactNode;
	dehydratedState?: unknown;
};
//#endregion



//#region [Component]
export default function QueryClientWrapper({ children, dehydratedState }: QueryClientWrapperProps) {
	const [queryClient] = useState(() => new QueryClient())

	return <QueryClientProvider client={queryClient}>
		<HydrationBoundary state={dehydratedState}>
			{children}

		</HydrationBoundary>
        {/*<ReactQueryDevtools />*/}
	</QueryClientProvider>;
}
//#endregion