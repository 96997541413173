"use client"
import Div100Vh from 'src/components/div100vh/Div100Vh';
import CloseSvg from 'src/components/svg/CloseSvg';
import theme from 'src/components/themes/DefaultTheme';
import styled from 'src/lib/styles/css';
import { rgba } from 'polished';
import { MouseEventHandler, ReactNode, useEffect } from 'react';
//#region [Styles]
const SFullPageOverlay = styled(Div100Vh)`
	background: ${rgba("#000000", 0.94)};
	position: fixed;
	width: 100vw;
	top: 0;
	left: 0;
	color: white;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SOverlayContent = styled.div`
	width: 100%;
	height: auto;
	max-height: 100vh;
	max-height: 100dvh;
	position: relative;

	@media ${theme.mq.desktop} {
		max-width: ${theme.maxContentWidth}px;
		padding: 0 ${theme.maxContentPadding};
	}
`;

const SCloseButton = styled.button`
	position: absolute;
	display: block;
	background: none;
	border: none;
	width: 40px;
	height: 40px;
	padding: 0;
	margin: 0;
	bottom: 10px;
	right: 50%;
	transform: translateX(50%);

	@media ${theme.mq.desktop} {
		top: 10px;
		right: 10px;
		bottom: unset;
		transform: none;
	}
`;
//#endregion

//#region [Props]
type FullPageOverlayProps = {
    children: ReactNode;
    onClose?: () => void;
    hideCloseButton?: boolean;
    closeOnClick?: boolean;
};
//#endregion

//#region [Component]
export default function FullPageOverlay({ children, onClose, hideCloseButton = false, closeOnClick = false }: FullPageOverlayProps) {
    useEffect(() => {
        document?.body?.classList?.add("noscroll");
        return () => {
            document?.body?.classList?.remove("noscroll");
        }
    }, []);
    return <SFullPageOverlay onClick={closeOnClick ? onClose : undefined}>
        <SOverlayContent>{children}</SOverlayContent>
        {!hideCloseButton && <SCloseButton onClick={onClose}><CloseSvg /></SCloseButton>}
    </SFullPageOverlay>;
}
//#endregion