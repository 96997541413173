import FilmwebLink from 'src/components/filmweblink/FilmwebLink';
import LocationSvg from 'src/components/svg/LocationSvg';
import { useLocationContext } from 'src/lib/contexts/LocationContext';
import { darken } from 'polished';
import styled from "src/lib/styles/css";
import theme from 'src/components/themes/DefaultTheme';

//#region [Styles]
const SLocationResult = styled(FilmwebLink)`
	display: flex;
	text-decoration: none;
	align-items: center;
	color: ${theme.textColor};

	&:hover {
		text-decoration: underline;
	}
`;

const SLocationIcon = styled.div`
	width: 75px;
	height: 75px;
	flex: 0 0 75px;
	border-radius: 50%;
	background-color: ${darken(0.1, theme.mainColor)};
	overflow: hidden;
	text-align: center;
`;

const SLocationName = styled.div`
	margin-left: 12px;
	font-size: 16px;
	flex: 1 1 auto;
`;

const SLocationSvg = styled(LocationSvg)`
	height: 50%;
	margin-top: 25%;
`
//#endregion

//#region [Props]
type LocationResultProps = {
	locationName: string;
	className?: string;
	onClick: (event: React.MouseEvent) => void;
};
//#endregion

//#region [Component]
export default function LocationResult({ locationName, onClick, className }: LocationResultProps) {
	const locationContext = useLocationContext();

	return <SLocationResult to={`/program?location=${locationName}`} className={className} onClick={e => { onClick(e); locationContext.setLocation(locationName); }}>
		<SLocationIcon>
			<SLocationSvg />
		</SLocationIcon>
		<SLocationName>
			{locationName}
		</SLocationName>
	</SLocationResult>;
}
//#endregion