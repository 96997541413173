type EnvConfig = {
	NEXT_PUBLIC_GOOGLE_SITE_VER: string;
	NEXT_PUBLIC_DEFAULT_DESCRIPTION: string;
	NEXT_PUBLIC_DEFAULT_TITLE: string;
	NEXT_PUBLIC_FILMINFO_ENDPOINT: string;
	NEXT_PUBLIC_FB_APPID: string;
	NEXT_PUBLIC_CHILD_PLAYER_ID: string;
	NEXT_PUBLIC_ADFREE_PLAYER_ID: string;
	NEXT_PUBLIC_AUTH_BASE: string;
	NEXT_PUBLIC_FILMWEBIDAPI_BASE: string;
	NEXT_PUBLIC_MINSIDE_BASE: string;

	NEXT_PUBLIC_SANITY_PROJECTID: string;
	NEXT_PUBLIC_SANITY_DATASET: string;
	NEXT_PUBLIC_KINOKLUBB_SANITY_PROJECTID: string;
	NEXT_PUBLIC_KINOKLUBB_SANITY_DATASET: string;

};

export const envConfig: EnvConfig = {
	NEXT_PUBLIC_GOOGLE_SITE_VER: process.env.NEXT_PUBLIC_GOOGLE_SITE_VER as string,
	NEXT_PUBLIC_DEFAULT_DESCRIPTION: process.env.NEXT_PUBLIC_DEFAULT_DESCRIPTION as string,
	NEXT_PUBLIC_DEFAULT_TITLE: process.env.NEXT_PUBLIC_DEFAULT_TITLE as string,
	NEXT_PUBLIC_FILMINFO_ENDPOINT: process.env.NEXT_PUBLIC_FILMINFO_ENDPOINT as string,
	NEXT_PUBLIC_FB_APPID: process.env.NEXT_PUBLIC_FB_APPID as string,
	NEXT_PUBLIC_CHILD_PLAYER_ID: process.env.NEXT_PUBLIC_CHILD_PLAYER_ID as string,
	NEXT_PUBLIC_ADFREE_PLAYER_ID: process.env.NEXT_PUBLIC_ADFREE_PLAYER_ID as string,
	NEXT_PUBLIC_AUTH_BASE: process.env.NEXT_PUBLIC_AUTH_BASE as string,
	NEXT_PUBLIC_FILMWEBIDAPI_BASE: process.env.NEXT_PUBLIC_FILMWEBIDAPI_BASE as string,
	NEXT_PUBLIC_MINSIDE_BASE: process.env.NEXT_PUBLIC_MINSIDE_BASE as string,
	NEXT_PUBLIC_SANITY_PROJECTID: process.env.NEXT_PUBLIC_SANITY_PROJECTID as string,
	NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET as string,
	NEXT_PUBLIC_KINOKLUBB_SANITY_PROJECTID: process.env.NEXT_PUBLIC_KINOKLUBB_SANITY_PROJECTID as string,
	NEXT_PUBLIC_KINOKLUBB_SANITY_DATASET: process.env.NEXT_PUBLIC_KINOKLUBB_SANITY_DATASET as string

};
/*
(() => {
	const propNames = Object.getOwnPropertyNames(envConfig);
	for (const propName of propNames) {
		const val = envConfig[propName as keyof EnvConfig];
		if (!envConfig[propName as keyof EnvConfig]) {
			throw new Error(
				`CLIENT: Couldn't find environment variable: ${propName}`
			);
		}
	}
})();*/