import { StandardSvgProps } from 'src/components/svg/StandardSvgProps';
import { SDefaultSvg, SFilledPath } from 'src/components/svg/SvgStyles';
import styled from "src/lib/styles/css";

//#region [Styles]
const SPathNoFill = styled.path`
	fill: none !important;
`;
//#endregion

//#region [Props]
type CheckboxSvgProps = {
	isChecked?: boolean;
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function CheckboxSvg({ className, isChecked = false }: CheckboxSvgProps) {
	return (<SDefaultSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		{!isChecked && <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />}
		{isChecked && <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />}
		<SPathNoFill d="M0 0h24v24H0z" />
	</SDefaultSvg>)
}
//#endregion