import { type ICustomImage } from "@filmweb/sanity-types";
import { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";
import { urlForAsset } from 'src/lib/client/imageUrls';
import { ImageLoader } from "next/image";
import type { ImageHotspot } from 'sanity';

const imageSizeRegExp = /^image-.*?-(\d+)x(\d+)-.*$/i;

type Size = {
	width: number,
	height: number,
	aspect: number
};

export function getImageUrlForWidth(image: ICustomImage, width: number): string {
	const size = getOrgSize(image.asset!._id ?? image.asset!._ref);
	const w = Math.min(width, size.width);
	const h = Math.round(w / size.aspect);
	let imgBuilder = urlForAsset(image.asset!._id ?? image.asset!._ref).auto("format").width(w).height(h);
	if (image.hotspot) {
		imgBuilder = doCrop(imgBuilder, image.asset?.hotspot);
	}
	return imgBuilder.url();
}

export function getOrgSize(assetRef: string): Size {

	const res = imageSizeRegExp.exec(assetRef);
	const w = parseInt(res![1]);
	const h = parseInt(res![2]);
	return { width: w, height: h, aspect: w / h };
}

export function doCrop(imageBuilder: ImageUrlBuilder, hotspot?: ImageHotspot): ImageUrlBuilder {
	let fpx = 0.5;
	let fpy = 0.5;
	if (hotspot) {
		fpx = hotspot.x;
		fpy = hotspot.y;
	}
	return imageBuilder.fit("crop").crop("focalpoint").focalPoint(fpx, fpy);
}

export function getImageLoader(crop: boolean, cropAspect: number, hotspot?: ImageHotspot): ImageLoader {
	let imgLoader: ImageLoader = (p) => {
		let builder = urlForAsset(p.src);
		if (crop) {
			builder = doCrop(builder, hotspot).width(p.width).height(Math.floor(p.width / cropAspect));
		}
		const url = builder.width(p.width).auto("format").url();
		return url;
	};


	return imgLoader;
}