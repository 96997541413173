import { createUrl } from "src/components/filmweblink/FilmwebLink";
import { WatchableContentType } from "src/lib/movieinfo/movieinfotypes";
import { ISearchDocument } from "src/lib/types/search";


export function getContentUrl(data: WatchableContentType, forceStreaming?: boolean) : string {
	//const streamingId = data.id || data.streamingContentId;
	const streamingId = data.streamingContentId;
	return createUrl((!forceStreaming && data.isCinemaRelevant) || !streamingId, data.movieId, streamingId, data.isSeries);
}

export function getContentUrlAzSearch(data: ISearchDocument, forceStreaming?: boolean): string {
    const streamingId = data.streamingId;
    return createUrl((!forceStreaming && (data.numShows ?? 0) > 0) || !streamingId, data.edi, streamingId, !data.isMovie)
}